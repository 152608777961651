"use strict";
'use es6';

(function () {
  const PAGE_SIZE = 10;
  const IN_SEQUENCE = 'IN_SEQUENCE';
  const ActivityTypes = ['EMAIL_OPEN', 'EMAIL_CLICK', 'EMAIL_TRACKER_CREATE', 'HUBSPOT_REVISIT', 'COMPANYPROSPECTS_REVISIT', 'PRESENTATION_REVISIT', 'MEETING_BOOKED', 'FORM_SUBMITTED'];
  const ContactPropertyTypes = ['IN_SEQUENCE', 'NOT_IN_SEQUENCE'];
  const ContactPropertyFilterMap = {
    IN_SEQUENCE: {
      property: 'hs_sequences_is_enrolled',
      operator: 'EQ',
      value: true
    },
    NOT_IN_SEQUENCE: {
      property: 'hs_sequences_is_enrolled',
      operator: 'NEQ',
      value: true
    }
  };
  if (!window.quickFetch) {
    return;
  }
  function getPageQueryParams() {
    const queryParamArray = window.location.search.split('?');
    if (queryParamArray.length !== 2) {
      return {};
    }
    return queryParamArray[1].split('&').reduce((map, pairString) => {
      const pairArray = pairString.split('=');
      const toAdd = {};
      toAdd[pairArray[0]] = decodeURIComponent(pairArray[1]);
      return Object.assign({}, map, toAdd);
    }, {});
  }
  function formatActivityFeedApiFilters(filter, enrolledStatus) {
    const filters = [];
    if (filter && ActivityTypes.indexOf(filter) !== -1) {
      filters.push({
        field: 'activityType',
        values: [filter]
      });
    } else {
      filters.push({
        field: 'activityType',
        values: ActivityTypes
      });
    }
    if (enrolledStatus === IN_SEQUENCE) {
      filters.push({
        field: 'activityOriginType',
        values: ['CONTACT']
      });
    }
    return filters;
  }
  function getOneMonthAgo() {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d.getTime();
  }
  const portalId = window.quickFetch.getPortalId();
  const queryParams = getPageQueryParams();
  const searchQuery = queryParams.searchQuery || queryParams.q || '';
  const activityType = queryParams.activityType || queryParams.filter;
  const enrolledStatus = queryParams.enrolledStatus || queryParams.contactProperty;
  const contactSearchFilters = ContactPropertyTypes.indexOf(enrolledStatus) > -1 ? [ContactPropertyFilterMap[enrolledStatus]] : [];
  const activitySearchQuery = {
    supportedActivityTypes: ActivityTypes,
    query: searchQuery,
    filters: formatActivityFeedApiFilters(activityType, enrolledStatus),
    contactSearchFilters,
    limit: PAGE_SIZE,
    offset: 0,
    createdBefore: Date.now(),
    createdAfter: 0
  };
  window.quickFetch.afterAuth(__authData => {
    if (searchQuery === '') {
      activitySearchQuery.createdAfter = getOneMonthAgo();
    }
    window.quickFetch.makeEarlyRequest('activity-search', {
      url: window.quickFetch.getApiUrl(`/activity-feed/v2/search/bundled?portalId=${portalId}`, true),
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      timeout: 12000,
      data: JSON.stringify(activitySearchQuery),
      xhrFields: {
        withCredentials: true
      }
    });
  });
})();

